<template>
  <div>
    <div :class="['input-main-select']">
      <label :for="label">
        <v-select
          :id="label"
          :placeholder="placeholder"
          :options="optionList"
          @input="onChange"
          :value="value"
          :label="labelName"
          :searchable="searchable"
          :disabled="disabled"
        >
        </v-select>
      </label>
    </div>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id" class="input-main-select__error">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputMainSelect",
  props: {
    optionList: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    labelName: {
      type: String,
      default: "name",
    },
    label: {
      type: String,
      default: "",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(value) {
      this.setValue[this.id] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-main-select {
  color: $mainColor;
  width: 230px;
  padding: 3px;
  border: 1px solid $mainColor;
  border-radius: 5px;
  display: flex;
  align-items: center;

  label {
    width: 100%;
  }

  &__error {
    color: $elementsColor;
    padding-left: 10px;
    padding-top: 10px;
    text-align: left;
    font-size: $g-font-small-size;
  }
}

/deep/ .vs__dropdown-toggle {
  border: none;
}
/deep/ .vs__dropdown-option--highlight {
  background-color: $mainColor;
}
/deep/ .vs__dropdown-menu {
  padding: 0;
  margin-top: 5px;
}
/deep/ .vs__clear {
  display: none;
}
</style>
