<template>
  <div v-if="url != null" class="image-preview">
    <img :src="url" alt="img" />
    <ButtonMain :buttonGrey="true" @click.native="hanbleChangePhoto(name)">Обновить</ButtonMain>
  </div>
</template>

<script>
import ButtonMain from "../atoms/buttons/ButtonMain.vue";
export default {
  props: {
    url: {
      type: String,
      default: null,
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    name: {
      type: String,
      default: "",
    },
  },
  components: {
    ButtonMain,
  },
  methods: {
    hanbleChangePhoto(img) {
      this.setValue[img] = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-preview {
  img {
    display: flex;
    flex-direction: column;
    width: 160px;
    height: 100px;
    margin-bottom: 15px;
    object-fit: cover;
  }
}
</style>
