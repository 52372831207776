<template>
  <div v-if="error.length" class="notification">{{ error[0] }}</div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    error: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  padding-left: 10px;
  padding-top: 10px;
  color: #eb5959;
  font-size: 12px;
}
</style>
