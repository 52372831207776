<template>
  <div>
    <div :class="['input-select-type-cars']">
      <label :for="label">
        <v-select
          :id="label"
          :placeholder="placeholder"
          :options="optionList"
          @input="onChange"
          :value="value"
          :searchable="searchable"
          :disabled="disabled"
          :label="'name'"
        >
          <template v-if="optionList.length" slot="option" slot-scope="option">
            <div class="d-center">
              <span class="list-item">{{ option.name }}</span> {{ option.description }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">{{ option.name }}</div>
          </template>
        </v-select>
      </label>
      <div class="select-icon">
        <SelectIcon />
      </div>
    </div>
    <div class="input-select-type-cars__error" v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id">
        {{
          error.indexOf("auth.") !== -1 || error.indexOf("user.") !== -1 ? error.slice(5) : error
        }}
      </p>
    </div>
  </div>
</template>

<script>
import SelectIcon from "../../../assets/images/icons/select-icon.svg";
export default {
  name: "InputMainSelect",
  props: {
    optionList: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    labelName: {
      type: String,
      default: "name",
    },
    label: {
      type: String,
      default: "",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(value) {
      this.setValue[this.id] = value;
    },
  },
  components: {
    SelectIcon,
  },
};
</script>

<style lang="scss" scoped>
.input-select-type-cars {
  position: relative;
  color: $mainColor;
  width: 100%;
  padding: 3px;
  border: 1px solid $mainColor;
  border-radius: 5px;
  height: 48px;

  &__error {
    color: $elementsColor;
    padding-top: 10px;
    width: 500px;
    font-size: $g-font-small-size;
  }

  .select-icon {
    position: absolute;
    top: 35%;
    right: 5px;
  }
}
/deep/.list-item {
  width: 70px;
  display: inline-block;
}
/deep/ .v-select {
  height: 100%;
}
/deep/ .vs__dropdown-toggle {
  border: none;
  height: 100%;
}
/deep/ .vs__dropdown-option--highlight {
  background-color: $mainColor;
}
/deep/ .vs__dropdown-menu {
  padding: 0;
  margin-top: 5px;
  font-size: 12px;
}
/deep/ .vs__clear {
  display: none;
}
/deep/ .vs__actions {
  display: none;
}
</style>
