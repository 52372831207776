<template>
  <Main>
    <div class="driver-create">
      <div class="driver-create__container">
        <div v-if="triggerUpdate" class="driver-create__header-container">
          <h1>Редактировать водителя</h1>
          <div v-if="driver != null" class="driver-create__date-create">
            Дата регистрации: {{ dateTransform }}
          </div>
        </div>
        <h1 v-else>Создать водителя</h1>
        <form @submit.prevent="sendNewDriver">
          <div class="driver-create__form-container">
            <div class="driver-create__label">Фамилия</div>
            <InputText
              :set-value="formDataToSend"
              :id="'lastName'"
              :label="'lastName'"
              :type="'text'"
              :placeholder="'Ввести'"
              :error-messages="error['lastName']"
              isBordered
            />
            <div class="driver-create__label">Имя</div>
            <InputText
              :set-value="formDataToSend"
              :id="'firstName'"
              :label="'firstName'"
              :type="'text'"
              :placeholder="'Ввести'"
              :error-messages="error['firstName']"
              isBordered
            />
            <div class="driver-create__label">Отчество</div>
            <InputText
              :set-value="formDataToSend"
              :id="'patronymic'"
              :label="'patronymic'"
              :type="'text'"
              :placeholder="'Ввести'"
              :error-messages="error['patronymic']"
              isBordered
            />
          </div>
          <div v-if="!triggerUpdate">
            <div class="driver-create__phone">
              <div class="driver-create__form-container">
                <div class="driver-create__label">Телефон</div>
                <InputText
                  :set-value="formDataToSend"
                  :id="'phone'"
                  :label="'phone'"
                  :type="'phone'"
                  :placeholder="'Ввести'"
                  :error-messages="error['phone']"
                  :thisPhone="true"
                  isBordered
                />
              </div>
              <div class="driver-create__create-code">
                <ButtonMain v-if="!limiterSendSms" @click.native="handleSendSms" :buttonGrey="true"
                  >Отправить код</ButtonMain
                >
                <div v-else class="driver-create__code-limiter">
                  Повторная отправка через 1 минуту
                </div>
              </div>
            </div>
            <Notification :error="errorSendSms['phone']" />
            <div class="driver-create__label">Код из смс</div>
            <div class="driver-create__code-container">
              <input
                @keyup="changeInput($event, 1)"
                ref="input_1"
                :value="formDataToSend.code[0]"
                :maxlength="1"
              />
              <input
                @keyup="changeInput($event, 2)"
                ref="input_2"
                :value="formDataToSend.code[1]"
                :maxlength="1"
              />
              <input
                @keyup="changeInput($event, 3)"
                ref="input_3"
                :value="formDataToSend.code[2]"
                :maxlength="1"
              />
              <input
                @keyup="changeInput($event, 4)"
                ref="input_4"
                :value="formDataToSend.code[3]"
                :maxlength="1"
              />
              <input
                @keyup="changeInput($event, 5)"
                ref="input_5"
                :value="formDataToSend.code[4]"
                :maxlength="1"
              />
            </div>
            <Notification :error="error['code']" />
            <Notification :error="error['message']" />
          </div>
          <div v-else class="driver-create__form-container">
            <div class="driver-create__label">Телефон</div>
            <InputText
              :set-value="formDataToSend"
              :id="'phone'"
              :label="'phone'"
              :type="'text'"
              :error-messages="error['phone']"
              isBordered
              readonly
            />
          </div>
          <div class="driver-create__form-container">
            <InputCheckboxMain
              :label="'Полная регистрация'"
              :id="'fullRegistration'"
              :set-value="formDataToSend"
            />
            <div class="driver-create__label">Город</div>
            <InputText
              :set-value="formDataToSend"
              :id="'city'"
              :label="'city'"
              :type="'text'"
              :placeholder="'Ввести'"
              :error-messages="error['city']"
              isBordered
            />
          </div>
          <div class="driver-create__label">Водительское удостоверение</div>
          <div class="driver-create__input-group driver-create__form-container">
            <div class="driver-create__group-item">
              <div class="driver-create__label-small">Серия удостоверения</div>
              <InputText
                :set-value="formDataToSend"
                :id="'driversLicenseSeries'"
                :label="'driversLicenseSeries'"
                :type="'text'"
                :placeholder="'Ввести'"
                :error-messages="error['driversLicenseSeries']"
                isBordered
              />
            </div>
            <div class="driver-create__group-item">
              <div class="driver-create__label-small">Серия удостоверения</div>
              <InputText
                :set-value="formDataToSend"
                :id="'driversLicenseNumber'"
                :label="'driversLicenseNumber'"
                :type="'number'"
                :placeholder="'Ввести'"
                :error-messages="error['driversLicenseNumber']"
                isBordered
              />
            </div>
          </div>
          <div class="driver-create__photo-group">
            <div class="driver-create__photo-group-item">
              <div class="driver-create__label">Лицевая сторона</div>
              <picture-input
                v-if="formDataToSend.urlFrontDriversLicensePhoto == null"
                ref="pictureInput"
                @change="handleChoosePhoto('frontDriversLicensePhoto')"
                accept="image/*"
                size="10"
                name="frontDriversLicensePhoto"
                buttonClass="create__btn-img"
                :customStrings="{
                  drag: '+',
                  change: 'Обновить',
                }"
              >
              </picture-input>
              <div v-else class="driver-create__photo-group-item">
                <ImagePreview
                  :url="formDataToSend.urlFrontDriversLicensePhoto"
                  :name="'urlFrontDriversLicensePhoto'"
                  :set-value="formDataToSend"
                />
              </div>
              <Notification :error="error['frontDriversLicensePhoto']" />
            </div>
            <div class="driver-create__photo-group-item">
              <div class="driver-create__label">Обратная сторона</div>
              <picture-input
                v-if="formDataToSend.urlBackDriversLicensePhoto == null"
                ref="pictureInput"
                @change="handleChoosePhoto('backDriversLicensePhoto')"
                accept="image/*"
                size="10"
                name="backDriversLicensePhoto"
                buttonClass="create__btn-img"
                :customStrings="{
                  drag: '+',
                  change: 'Обновить',
                }"
              >
              </picture-input>
              <div v-else class="driver-create__photo-group-item">
                <ImagePreview
                  :url="formDataToSend.urlBackDriversLicensePhoto"
                  :name="'urlBackDriversLicensePhoto'"
                  :set-value="formDataToSend"
                />
              </div>
              <Notification :error="error['backDriversLicensePhoto']" />
            </div>
          </div>
          <div class="driver-create__form-container">
            <div class="driver-create__label">Тип</div>
            <InputMainSelect
              :id="'bodyType'"
              :placeholder="'Выбрать'"
              :option-list="bodyTypes"
              :set-value="formDataToSend"
              :value="formDataToSend.bodyType"
              :label-name="'name'"
              :error-messages="error['carBodyTypeId']"
            />
            <div class="driver-create__input-group">
              <div class="driver-create__group-item">
                <div class="driver-create__label">Марка</div>
                <InputMainSelect
                  :id="'brand'"
                  :placeholder="'Выбрать'"
                  :label-name="'name'"
                  :option-list="brands"
                  :set-value="formDataToSend"
                  :value="formDataToSend.brand"
                  :error-messages="error['carBrandId']"
                />
              </div>
              <div class="driver-create__group-item">
                <div class="driver-create__label">Модель</div>
                <InputText
                  :set-value="formDataToSend"
                  :id="'model'"
                  :label="'model'"
                  :type="'text'"
                  :placeholder="'Ввести'"
                  :error-messages="error['carModel']"
                  isBordered
                />
              </div>
            </div>
            <div class="driver-create__input-group">
              <div class="driver-create__group-item">
                <div class="driver-create__label">Номер авто</div>
                <InputText
                  :set-value="formDataToSend"
                  :id="'carNumber'"
                  :label="'carNumber'"
                  :type="'text'"
                  :placeholder="'Ввести'"
                  :error-messages="error['carCarNumber']"
                  isBordered
                />
              </div>
              <div class="driver-create__group-item">
                <div class="driver-create__label">Год выпуска</div>
                <InputMainSelect
                  :id="'yearOfCarManufacture'"
                  :placeholder="'Выбрать'"
                  :label-name="'name'"
                  :option-list="years"
                  :set-value="formDataToSend"
                  :value="formDataToSend.yearOfCarManufacture"
                  :error-messages="error['carYearOfCarManufacture']"
                />
              </div>
            </div>
            <div class="driver-create__input-group">
              <div class="driver-create__group-item">
                <div class="driver-create__label">Номер прицепа</div>
                <InputText
                  :set-value="formDataToSend"
                  :id="'trailerNumber'"
                  :label="'trailerNumber'"
                  :type="'text'"
                  :placeholder="'Ввести'"
                  :error-messages="error['carTrailerNumber']"
                  isBordered
                />
              </div>
              <div class="driver-create__group-item">
                <div class="driver-create__label">Год выпуска</div>
                <InputMainSelect
                  :id="'yearOfTrailerManufacture'"
                  :placeholder="'Выбрать'"
                  :label-name="'name'"
                  :option-list="years"
                  :set-value="formDataToSend"
                  :value="formDataToSend.yearOfTrailerManufacture"
                  :error-messages="error['carYearOfTrailerManufacture']"
                />
              </div>
            </div>
            <div class="driver-create__label">Свидетельство регистрации авто:</div>
            <div class="driver-create__picture-vertical">
              <picture-input
                v-if="formDataToSend.urlCarRegistrationCertificatePhoto == null"
                ref="pictureInput"
                @change="handleChoosePhoto('carRegistrationCertificatePhoto')"
                accept="image/*"
                size="10"
                name="carRegistrationCertificatePhoto"
                buttonClass="create__btn-img"
                :customStrings="{
                  drag: '+',
                  change: 'Обновить',
                }"
              >
              </picture-input>
              <div v-else class="driver-create__photo-group-item">
                <ImagePreview
                  :url="formDataToSend.urlCarRegistrationCertificatePhoto"
                  :name="'urlCarRegistrationCertificatePhoto'"
                  :set-value="formDataToSend"
                />
              </div>
            </div>
            <Notification :error="error['carCarRegistrationCertificatePhoto']" />
            <div>
              <div class="driver-create__label">Свидетельство регистрации прицепа:</div>
              <div class="driver-create__picture-vertical">
                <picture-input
                  v-if="formDataToSend.urlTrailerRegistrationCertificatePhoto == null"
                  ref="pictureInput"
                  @change="handleChoosePhoto('trailerRegistrationCertificatePhoto')"
                  accept="image/*"
                  size="10"
                  name="trailerRegistrationCertificatePhoto"
                  buttonClass="create__btn-img"
                  :customStrings="{
                    drag: '+',
                    change: 'Обновить',
                  }"
                >
                </picture-input>
                <div v-else class="driver-create__photo-group-item">
                  <ImagePreview
                    :url="formDataToSend.urlTrailerRegistrationCertificatePhoto"
                    :name="'urlTrailerRegistrationCertificatePhoto'"
                    :set-value="formDataToSend"
                  />
                </div>
              </div>
              <Notification :error="error['carTrailerRegistrationCertificatePhoto']" />
            </div>
            <div class="driver-create__label">Тип авто</div>
            <div class="driver-create__type-car">
              <InputSelectTypeCars
                :id="'typesOfCars'"
                :placeholder="'Выбрать'"
                :option-list="typesOfCars"
                :set-value="formDataToSend"
                :value="formDataToSend.typesOfCars"
                :error-messages="error['carTypeOfCarId']"
              />
            </div>
            <div
              v-if="formDataToSend.typesOfCars != null && formDataToSend.typesOfCars.id == 5"
              class="driver-create__nonstandart"
            >
              <div class="driver-create__nonstandart_item">
                <div class="driver-create__label">Вес</div>
                <InputText
                  :set-value="formDataToSend"
                  :id="'weight'"
                  :label="'weight'"
                  :type="'number'"
                  isBordered
                ></InputText>
              </div>
              <div class="driver-create__nonstandart_item">
                <div class="driver-create__label">Длина</div>
                <InputText
                  :set-value="formDataToSend"
                  :id="'length'"
                  :label="'length'"
                  :type="'number'"
                  isBordered
                ></InputText>
              </div>
              <div class="driver-create__nonstandart_item">
                <div class="driver-create__label">Ширина</div>
                <InputText
                  :set-value="formDataToSend"
                  :id="'width'"
                  :label="'width'"
                  :type="'number'"
                  isBordered
                ></InputText>
              </div>
              <div class="driver-create__nonstandart_item">
                <div class="driver-create__label">Висота</div>
                <InputText
                  :set-value="formDataToSend"
                  :id="'height'"
                  :label="'height'"
                  :type="'number'"
                  isBordered
                ></InputText>
              </div>
              <div class="driver-create__nonstandart_item">
                <div class="driver-create__label">Объем</div>
                <InputText
                  :set-value="formDataToSend"
                  :id="'volume'"
                  :label="'volume'"
                  :type="'number'"
                  isBordered
                ></InputText>
              </div>
            </div>
            <div class="driver-create__label">Фото авто</div>
            <div class="driver-create__car-img">
              <div class="driver-create__car-img-item">
                <picture-input
                  v-if="formDataToSend.urlFrontPhoto == null"
                  ref="pictureInput"
                  @change="handleChoosePhoto('frontPhoto')"
                  accept="image/*"
                  size="10"
                  name="frontPhoto"
                  buttonClass="create__btn-img"
                  :customStrings="{
                    drag: '+',
                    change: 'Обновить',
                  }"
                >
                </picture-input>
                <div v-else class="driver-create__photo-group-item">
                  <ImagePreview
                    :url="formDataToSend.urlFrontPhoto"
                    :name="'urlFrontPhoto'"
                    :set-value="formDataToSend"
                  />
                </div>
                <Notification :error="error['carFrontPhoto']" />
              </div>
              <div class="driver-create__car-img-item">
                <picture-input
                  v-if="formDataToSend.urlBackPhoto == null"
                  ref="pictureInput"
                  @change="handleChoosePhoto('backPhoto')"
                  accept="image/*"
                  size="10"
                  name="backPhoto"
                  buttonClass="create__btn-img"
                  :customStrings="{
                    drag: '+',
                    change: 'Обновить',
                  }"
                >
                </picture-input>
                <div v-else class="driver-create__photo-group-item">
                  <ImagePreview
                    :url="formDataToSend.urlBackPhoto"
                    :name="'urlBackPhoto'"
                    :set-value="formDataToSend"
                  />
                </div>
                <Notification :error="error['carBackPhoto']" />
              </div>
              <div class="driver-create__car-img-item">
                <picture-input
                  v-if="formDataToSend.urlRightPhoto == null"
                  ref="pictureInput"
                  @change="handleChoosePhoto('rightPhoto')"
                  accept="image/*"
                  size="10"
                  name="rightPhoto"
                  buttonClass="create__btn-img"
                  :customStrings="{
                    drag: '+',
                    change: 'Обновить',
                  }"
                >
                </picture-input>
                <div v-else class="driver-create__photo-group-item">
                  <ImagePreview
                    :url="formDataToSend.urlRightPhoto"
                    :name="'urlRightPhoto'"
                    :set-value="formDataToSend"
                  />
                </div>
                <Notification :error="error['carRightPhoto']" />
              </div>
              <div class="driver-create__car-img-item">
                <picture-input
                  v-if="formDataToSend.urlLeftPhoto == null"
                  ref="pictureInput"
                  @change="handleChoosePhoto('leftPhoto')"
                  accept="image/*"
                  size="10"
                  name="leftPhoto"
                  buttonClass="create__btn-img"
                  :customStrings="{
                    drag: '+',
                    change: 'Обновить',
                  }"
                >
                </picture-input>
                <div v-else class="driver-create__photo-group-item">
                  <ImagePreview
                    :url="formDataToSend.urlLeftPhoto"
                    :name="'urlLeftPhoto'"
                    :set-value="formDataToSend"
                  />
                </div>
                <Notification :error="error['carLeftPhoto']" />
              </div>
            </div>
          </div>
          <div class="driver-create__submit">
            <div v-if="triggerUpdate" class="driver-create__submit-group">
              <ButtonMain @click.native="handleOpenDeleteModal" :buttonDark="true">
                Удалить
              </ButtonMain>
              <ButtonMain @click.native="handleOpenConfirmModal"> Сохранить </ButtonMain>
            </div>
            <ButtonMain v-else :type="'submit'">Создать</ButtonMain>
          </div>
        </form>
      </div>
    </div>
    <DefaultModal v-if="updateModal" :on-close="handleCloseConfirmModal">
      <ConfirmModalContent :on-cancel="handleCloseConfirmModal" :on-success="sendNewDriver">
        <template v-slot:description>
          <p>Вы уверены, что хотите редактировать водителя?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal v-if="error.errors" :on-close="handleCloseErrorModal">
      <ConfirmModalContent :on-cancel="handleCloseErrorModal" :on-success="handleCloseErrorModal">
        <template v-slot:description>
          <p>{{ error.errors[0].message }}</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal
      class="driver-create__confirm-modal"
      v-if="deleteModal"
      :on-close="handleCloseDeleteModal"
    >
      <ConfirmModalContent :on-cancel="handleCloseDeleteModal" :on-success="handleDelete">
        <template v-slot:description>
          <p>
            Вы уверены, что хотите удалить водителя {{ driver.lastName }} {{ driver.firstName }}
            {{ driver.patronymic }}?
          </p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import PictureInput from "vue-picture-input";
import { driverSendData, phoneNumberIteration, driverListReverse } from "../helpers/dataIteration";
import { dateTransform } from "../helpers/dateHelper";
import driversApi from "../api/drivers";
import ROUTE from "../constants/routes";

import Main from "../templets/Main.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
import InputText from "../components/atoms/inputs/InputText.vue";
import InputMainSelect from "../components/atoms/inputs/InputMainSelect.vue";
import InputSelectTypeCars from "../components/atoms/inputs/InputSelectTypeCars.vue";
import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal.vue";
import Notification from "../components/molecules/Notificatin.vue";
import DefaultModal from "../components/molecules/DefaultModal.vue";
import ConfirmModalContent from "../components/molecules/ConfirmModalContent.vue";
import ImagePreview from "../components/molecules/ImagePreview.vue";
import InputCheckboxMain from "../components/atoms/inputs/InputCheckboxMain.vue";

export default {
  data() {
    return {
      formDataToSend: {
        code: ["", "", "", "", ""],
        brand: null,
        bodyType: null,
        yearOfCarManufacture: null,
        yearOfTrailerManufacture: null,
        trailerNumber: null,
        typesOfCars: null,
        firstName: "",
        lastName: "",
        patronymic: "",
        driversLicenseSeries: "",
        driversLicenseNumber: "",
        model: "",
        carNumber: "",
        trailerNumber: "",
        urlFrontDriversLicensePhoto: null,
        urlBackDriversLicensePhoto: null,
        urlCarRegistrationCertificatePhoto: null,
        urlTrailerRegistrationCertificatePhoto: null,
        urlFrontPhoto: null,
        urlLeftPhoto: null,
        urlRightPhoto: null,
        urlBackPhoto: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        volume: null,
        phone: "",
        city: "",
        fullRegistration: true,
      },
      years: [],
      updateModal: false,
      deleteModal: false,
    };
  },
  components: {
    Main,
    ButtonMain,
    InputText,
    PictureInput,
    InputMainSelect,
    InputSelectTypeCars,
    FetchSpinnerModal,
    Notification,
    DefaultModal,
    ConfirmModalContent,
    ImagePreview,
    InputCheckboxMain,
  },
  mounted() {
    this.getTypesOfCars();
    this.getBrands();
    this.getBodyTypes();
    this.getYear();
    this.getLoadingTypes();
    if (this.triggerUpdate) {
      const { params } = this.$route;
      const url = `/${params.driverId}`;
      driversApi.getDriver(url).then((res) => {
        this.formDataToSend = driverListReverse(this.formDataToSend, res.data);
      });
      this.getDriverDetail();
    }
  },
  computed: {
    ...mapState("drivers", ["error", "loadingProcess", "driver"]),
    ...mapState("common", ["typesOfCars", "bodyTypes", "brands", "errorSendSms", "limiterSendSms"]),
    triggerUpdate() {
      if (this.$route.params.process === "update") {
        return true;
      }
      return false;
    },
    dateTransform() {
      return dateTransform(this.driver.registerAt);
    },
  },
  methods: {
    async getDriverDetail() {
      const { params } = this.$route;
      const url = `/${params.driverId}`;
      await this.$store.dispatch("drivers/getDriverDetail", url);
    },
    handleCloseErrorModal() {
      this.$store.commit("drivers/setError", {});
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.formDataToSend.code = ["", "", "", "", ""];
    },
    sendNewDriver() {
      const data = driverSendData(this.formDataToSend);
      if (this.triggerUpdate) {
        const url = `/${this.driver.id}`;
        this.$store.dispatch("drivers/updateDriver", { url: url, dataDriver: data });
        this.handleCloseConfirmModal();
      } else {
        this.$store.dispatch("drivers/createDriver", data);
      }
    },
    async getBrands() {
      await this.$store.dispatch("common/getBrands");
    },
    async getBodyTypes() {
      await this.$store.dispatch("common/getBodyTypes");
    },
    changeInput: function (event, input) {
      let val = event.target.value;
      if (val.length >= 1) {
        let next = this.$refs["input_" + (input + 1)];
        if (next) {
          next.focus();
        } else {
          event.preventDefault();
        }
      }
      this.$set(this.formDataToSend.code, input - 1, val);
    },
    handleChoosePhoto(name) {
      this.formDataToSend[name] = document.querySelector(`input[name="${name}"]`).files[0];
    },
    handleSendSms() {
      if (this.formDataToSend.phone != undefined && this.formDataToSend.phone.length > 0) {
        const data = {
          phone: phoneNumberIteration(this.formDataToSend.phone),
          type: "registration",
        };
        this.$store.dispatch("common/sendSms", data);
      }
    },
    getTypesOfCars() {
      this.$store.dispatch("common/getTypesOfCars");
    },
    getLoadingTypes() {
      this.$store.dispatch("common/getLoadingTypes");
    },
    getYear() {
      const thisYear = new Date().getFullYear();
      const yearArray = [];
      yearArray.push({ name: thisYear });
      while (yearArray[yearArray.length - 1].name != 1980) {
        yearArray.push({ name: yearArray[yearArray.length - 1].name - 1 });
      }

      this.years = yearArray;
    },
    handleOpenConfirmModal() {
      this.updateModal = true;
    },
    handleCloseConfirmModal() {
      this.updateModal = false;
    },
    handleOpenDeleteModal() {
      this.deleteModal = true;
    },
    handleCloseDeleteModal() {
      this.deleteModal = false;
    },
    handleDelete() {
      const url = `/${this.driver.id}`;
      this.$store.dispatch("drivers/deleteDriver", url).finally(() => {
        this.handleCloseConfirmModal();
        this.$router.push({
          path: ROUTE.DRIVERS_PAGE.replace(":page", 1),
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.driver-create {
  width: 100%;
  &__container {
    padding: 25px 17px;
  }

  &__form-container {
    width: 360px;
  }

  &__label {
    font-size: $g-font-base-size;
    color: $mainSecondTextColor;
    padding: 27px 0 7px;

    &.no-padding {
      padding-top: 0;
    }
    &.padding-bottom {
      padding-bottom: 10px;
    }
  }

  &__phone {
    display: flex;
    align-items: flex-end;
  }

  &__create-code {
    padding-left: 30px;
  }

  &__code-container {
    input {
      margin-right: 10px;
      padding: 10px 10px 10px 17px;
      width: 48px;
      height: 48px;
      font-size: 18px;
      border: 1px solid #2f2f34;
      border-radius: 5px;
    }
  }

  &__submit {
    width: 359px;
  }

  &__input-group {
    display: flex;
    justify-content: space-between;

    .driver-create__group-item {
      width: 48%;
    }
  }

  &__label-small {
    color: #7d7d7d;
    font-size: 12px;
    padding-bottom: 5px;
  }

  &__photo-group {
    display: flex;
    justify-content: space-between;
    width: 360px;
  }

  &__car-img {
    display: flex;
    padding-bottom: 20px;

    &-item {
      margin-right: 10px;
    }
  }

  &__header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__date-create {
    font-size: 14px;
    color: #7d7d7d;
  }

  &__submit-group {
    display: flex;
    justify-content: space-between;

    /deep/.button-main {
      width: 48%;
    }
  }

  &__picture-vertical {
    display: flex;

    /deep/.picture-input {
      div {
        display: flex;
      }

      .preview-container {
        display: block;
      }
    }

    /deep/.create__btn-img {
      margin: auto 0 auto 10px !important;
    }

    /deep/.image-preview {
      display: flex;

      .button-main {
        width: 189px;
        margin: auto 0 auto 10px;
      }
    }
  }

  &__nonstandart {
    display: flex;
  }

  &__nonstandart_item {
    min-width: 160px;
    margin-right: 10px;
  }

  /deep/.checkbox-input__wrap {
    padding-top: 15px;
  }

  /deep/.input-main-select {
    width: 100%;
    height: 48px;
  }

  /deep/.picture-input {
    text-align: left;
  }

  /deep/.preview-container {
    margin: 0;
    background: #fff;
    max-width: 172px !important;
    height: 100px !important;
  }

  /deep/.picture-preview {
    width: 172px !important;
    height: 100px !important;
    z-index: 1 !important;
  }

  /deep/.create__btn-img {
    margin: 15px 0 0;
    padding: 10px 15px;
    width: 100%;
    height: 36px;
    font-size: 13px;
    background: #d8d8d8;
    color: #000;
    border: none;
    border-radius: 5px;

    &:hover {
      background: #c6c6c6;
    }
  }

  /deep/.picture-inner {
    border: 1px solid black;
    top: -104px !important;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    z-index: 1 !important;
  }

  /deep/.picture-inner-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: $mainSecondColor;
    border-radius: 50px;
    font-size: 34px;
    line-height: 24px;
  }
}
</style>
